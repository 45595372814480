import { Box, Skeleton, Typography } from '@mui/material'
import { ButtonCustom } from 'components/Button'
import React, { FC } from 'react'

import { Props } from './NoResults.types'

export const NoResults: FC<Props> = ({
  title = '',
  description,
  buttonTitle,
  onClick,
  loading,
  isButtonDisabled,
  buttonAsLink,
  buttonLink,
}) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box
        sx={{
          maxWidth: '664px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '0 auto',
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        {title &&
          (loading ? (
            <Skeleton variant={'text'}>
              <Typography variant={'h2'}>{title}</Typography>
            </Skeleton>
          ) : (
            <Typography variant={'h2'}>{title}</Typography>
          ))}
        {loading ? (
          <Skeleton
            variant={'text'}
            sx={{ margin: '17px 0 26px', textAlign: 'center' }}
          >
            <Typography>{description}</Typography>
          </Skeleton>
        ) : (
          <Typography sx={{ margin: '17px 0 26px', textAlign: 'center' }}>
            {description}
          </Typography>
        )}
        {buttonAsLink
          ? buttonLink
          : buttonTitle &&
            onClick && (
              <ButtonCustom
                onClick={onClick}
                disabled={isButtonDisabled}
                width='auto'
                height='40px'
                fontSize='16px'
                loading={loading}
              >
                {buttonTitle}
              </ButtonCustom>
            )}
      </Box>
    </Box>
  )
}
