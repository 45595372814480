import { Skeleton, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { GridViewIcon, ListViewIcon } from 'icons'
import { FC } from 'react'

import { Props } from './ToggleViewButton.types'

export const ToggleViewButton: FC<Props> = ({ view, onChange, loading }) => {
  return loading ? (
    <Skeleton>
      <ToggleButtonGroup sx={{ width: '104px' }} />
    </Skeleton>
  ) : (
    <ToggleButtonGroup
      value={view}
      exclusive
      onChange={onChange}
      aria-label='view'
      sx={{
        '.Mui-selected': {
          backgroundColor: 'grey.50',
          path: {
            fill: '#009970',
          },
        },
      }}
    >
      <ToggleButton
        value='grid'
        aria-label='grid'
        sx={{
          border: '1px solid rgba(34, 34, 34, 0.12)',
          borderRadius: '24px',
          width: '52px',
        }}
      >
        <GridViewIcon width={24} height={24} color='#222' />
      </ToggleButton>
      <ToggleButton
        value='list'
        aria-label='list'
        sx={{ borderRadius: '24px', width: '52px' }}
      >
        <ListViewIcon width={24} height={24} color='#222' />
      </ToggleButton>
    </ToggleButtonGroup>
  )
}
