import { Pagination, PaginationItem } from '@mui/material'
import { FC } from 'react'

import { Props } from './Pagination.types'

export const ItemPagination: FC<Props> = ({
  count = 10,
  page = 0,
  onChange,
}) => {
  return (
    <Pagination
      page={page}
      count={count}
      onChange={onChange}
      size={'large'}
      sx={{
        '&.MuiPagination-root': {
          mt: '56px',
        },
        '.MuiPagination-ul': {
          justifyContent: 'center',
        },
      }}
      renderItem={item => (
        <PaginationItem
          sx={{
            width: '48px',
            height: '48px',
            borderRadius: '50%',
            fontSize: '18px',
            m: '0 4px',
            '&.Mui-selected': {
              fontWeight: 600,
              border: '2px solid',
              borderColor: 'primary.main',
              background: 'white',
            },
          }}
          {...item}
        />
      )}
    />
  )
}
