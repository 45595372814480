import { Grid } from '@mui/material'
import { AuctionCardHorizontal } from 'components/Card/AuctionCardHorizontal'
import { AuctionCardVertical } from 'components/Card/AuctionCardVertical'
import { useUserContext } from 'hooks'
import { capitalize } from 'lodash'
import React, { FC } from 'react'

import { Props } from './AuctionCardsView.types'

export const AuctionCardsView: FC<Props> = ({
  view,
  data,
  xs = 12,
  sm = 6,
  lg = 4,
  onRemoveWatchlistFinish,
  loading,
  alwaysDisplayBid = false,
}) => {
  const { user } = useUserContext()

  return (
    <Grid container spacing={view === 'grid' ? '16px' : '0'}>
      {data?.data?.map(
        ({
          auction: {
            id,
            status,
            isFavourite,
            endDate,
            overdueTime,
            startingBidPrice,
          },
          location: { state, country },
          lot: { title, attributes, condition, photos, itemCount },
          packages,
          bids,
          totalBids,
          hasReservePrice,
          isReservePriceMet,
          isMyBidHighest,
          currentBidPrice,
          winningPrice,
        }) => {
          const manufacturer = attributes?.length
            ? attributes
                ?.filter(({ name }) => name === 'Manufacturer')
                ?.map(({ value }) => value)
            : undefined

          const typeAttributes = attributes?.length
            ? attributes
                ?.filter(({ name }) => name !== 'Manufacturer')
                ?.map(({ value }) => value)
            : undefined

          const address = `${capitalize(state)}, ${
            country?.toLowerCase() === 'united states'
              ? 'USA'
              : capitalize(country)
          }`
          const image = photos[0]
          const totalWeight = packages?.reduce(
            (acc, { weight }) => acc + weight,
            0
          )

          const isUserParticipatedInAuction =
            user &&
            bids.some(
              bid => bid.userId === user.id || bid.userId === user.ownerId
            )

          const myMaxBid =
            user &&
            Math.max(
              ...bids
                .filter(
                  bid => bid.userId === user.id || bid.userId === user.ownerId
                )
                .map(bid => Number(bid.price))
            )

          return view === 'grid' ? (
            <Grid key={id} item xs={xs} sm={sm} lg={lg}>
              <AuctionCardVertical
                onRemoveWatchlistFinish={onRemoveWatchlistFinish}
                image={image}
                auctionId={id}
                isFavourite={isFavourite}
                status={status}
                address={address}
                title={title}
                manufacturer={manufacturer}
                condition={condition}
                typeAttributes={typeAttributes}
                isCurrentAuction={Boolean(isUserParticipatedInAuction)}
                packages={packages}
                totalBids={totalBids}
                myMaxBid={myMaxBid ?? 0}
                isMyBidHighest={isMyBidHighest}
                startingBidPrice={startingBidPrice}
                currentBidPrice={currentBidPrice}
                winningBidPrice={winningPrice ?? 0}
                itemCount={itemCount || 0}
                totalWeight={totalWeight}
                endDate={new Date(overdueTime || endDate)}
                loading={loading}
                isReservePriceMet={isReservePriceMet}
                hasReservePrice={hasReservePrice}
                alwaysDisplayBid={alwaysDisplayBid}
              />
            </Grid>
          ) : (
            <AuctionCardHorizontal
              onRemoveWatchlistFinish={onRemoveWatchlistFinish}
              image={image}
              key={id}
              auctionId={id}
              isFavourite={isFavourite}
              status={status}
              address={address}
              title={title}
              manufacturer={manufacturer}
              condition={condition}
              typeAttributes={typeAttributes}
              isCurrentAuction={Boolean(isUserParticipatedInAuction)}
              packages={packages}
              totalBids={totalBids}
              myMaxBid={myMaxBid ?? 0}
              isMyBidHighest={isMyBidHighest}
              startingBidPrice={startingBidPrice}
              currentBidPrice={currentBidPrice}
              winningBidPrice={winningPrice ?? 0}
              itemCount={itemCount || 0}
              totalWeight={totalWeight}
              endDate={new Date(overdueTime || endDate)}
              isReservePriceMet={isReservePriceMet}
              hasReservePrice={hasReservePrice}
            />
          )
        }
      )}
    </Grid>
  )
}
