import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Collapse,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/material'
import { addWatchlist, removeWatchlist } from 'api/requests/auction'
import { AttributesRow } from 'components/AttributesRow'
import { AuctionStatus } from 'components/AuctionStatus'
import { AuctionTimer } from 'components/AuctionTimer'
import { BidLabel } from 'components/BidLabel'
import { CornerLabel } from 'components/CornerLabel'
import { Label } from 'components/Label'
import { ModalCustom } from 'components/ModalCustom'
import { PriceStatus } from 'components/PriceStatus'
import { TooltipForEllipsis } from 'components/TooltipForEllipsis'
import { WatchlistIconButton } from 'components/WatchlistIconButton'
import { FILE_SERVER_URL } from 'constants/urls'
import { useUserContext } from 'hooks'
import { ClearIcon } from 'icons'
import { capitalize } from 'lodash'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import PlaceholderImage from 'public/images/image-placeholder.png'
import React, { FC, MouseEvent, useMemo, useState } from 'react'
import { AuctionStatus as AuctionStatusEnum, UserRoles } from 'types/enums'
import { getPackagesTitle } from 'utils/auction'
import { formatCurrency, formatDate } from 'utils/format'

import { Props } from './AuctionCardHorizontal.types'

export const AuctionCardHorizontal: FC<Props> = ({
  auctionId,
  status,
  address,
  image,
  title,
  manufacturer,
  condition,
  typeAttributes,
  isCurrentAuction = false,
  packages,
  totalBids,
  myMaxBid,
  isMyBidHighest,
  startingBidPrice,
  currentBidPrice,
  winningBidPrice,
  itemCount,
  totalWeight,
  isFavourite = false,
  onRemoveWatchlistFinish,
  endDate,
  isReservePriceMet,
  hasReservePrice,
}) => {
  const router = useRouter()

  const { user } = useUserContext()
  const isAuthenticated = Boolean(user)
  const isBuyer = Boolean(
    user?.roles.some(role => role.code === UserRoles.Buyer)
  )

  const totalPackages = useMemo(() => getPackagesTitle(packages), [packages])
  const restAttrs = typeAttributes ? [...typeAttributes] : []
  const splicedAttrs = restAttrs.splice(0, 3)
  const [selectedWatchlist, setSelectedWatchlist] = useState(isFavourite)

  const [open, setOpen] = useState(false)
  const [openRemoveModal, setOpenRemoveModal] = useState(false)

  const [authRequiredModalOpen, setAuthRequiredModalOpen] = useState(false)

  const bidToShow =
    status === 'active' && !totalBids
      ? startingBidPrice
      : status === 'sold'
      ? winningBidPrice
      : currentBidPrice

  const handleWatchlistChange = async (
    event: React.MouseEvent<HTMLElement>
  ) => {
    event.preventDefault()

    if (!isAuthenticated) {
      setAuthRequiredModalOpen(true)
      return
    }

    if (auctionId) {
      try {
        if (selectedWatchlist) {
          setOpenRemoveModal(true)
        } else {
          await addWatchlist({ auctionId })
          setSelectedWatchlist(!selectedWatchlist)
        }
      } catch (e) {
        console.error(e)
      }
    }
  }

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault()
    setOpen(!open)
    return false
  }

  const handleAcceptDeleting = async () => {
    if (auctionId) {
      try {
        await removeWatchlist(auctionId)
        setSelectedWatchlist(!selectedWatchlist)
        onRemoveWatchlistFinish?.()
      } catch (e) {
        console.error(e)
      } finally {
        setOpenRemoveModal(false)
      }
    }
  }

  return (
    <Box
      sx={{
        textDecoration: 'none',
        color: 'inherit',
        mb: '24px',
        width: '100%',
        cursor: 'pointer',
      }}
    >
      <Link href={`/auctions/${auctionId}`}>
        <Card
          sx={{
            borderRadius: '16px',
            display: 'flex',
            flexWrap: {
              xs: 'wrap',
              lg: 'nowrap',
            },
            p: {
              xs: '10px',
              lg: '13px 16px',
            },
            boxShadow:
              '0px 0px 2px rgba(34, 34, 34, 0.16), 0px 1px 2px rgba(34, 34, 34, 0.08)',
            ':hover': {
              boxShadow:
                '0px 0px 2px rgba(34, 34, 34, 0.1), 0px 4px 9px rgba(34, 34, 34, 0.06)',
            },
          }}
        >
          <Box
            sx={{
              position: 'relative',
              width: {
                xs: '240px',
                md: '320px',
                lg: '295px',
              },
              height: '216px',
              borderRadius: '8px',
              overflow: 'hidden',
            }}
          >
            {image ? (
              <CardMedia
                component='img'
                height='216'
                image={`${FILE_SERVER_URL}${image}`}
                alt='lot'
              />
            ) : (
              <Image src={PlaceholderImage} alt='' />
            )}
            {isCurrentAuction && <CornerLabel title={'Current Auction'} />}
            {(!isAuthenticated || isBuyer) && (
              <WatchlistIconButton
                selected={selectedWatchlist}
                onChange={handleWatchlistChange}
              />
            )}
          </Box>
          <CardContent
            sx={{
              p: '0',
              flex: 1,
              maxWidth: {
                md: 'unset',
              },
              m: '0 24px 0 16px',
            }}
          >
            <TooltipForEllipsis
              title={title}
              sx={{
                fontWeight: 600,
                lineHeight: '24px',
                marginBottom: '12px',
                display: '-webkit-box',
                whiteSpace: 'initial',
                WebkitLineClamp: '2',
                WebkitBoxOrient: 'vertical',
                wordBreak: 'break-word',
              }}
            />
            <Stack
              direction={'row'}
              spacing={'4px'}
              sx={{ mb: '11px', position: 'relative' }}
            >
              {/*{typeAttributes?.map(attr => (*/}
              {/*  <Label key={attr} title={attr} />*/}
              {/*))}*/}
              {splicedAttrs?.map(attr => (
                <Label key={attr} title={attr} />
              ))}
              {!!restAttrs.length && (
                <>
                  <Button
                    onClick={handleClick}
                    aria-label='show more'
                    sx={{
                      borderRadius: '11px',
                      height: '20px',
                      width: 'fit-content',
                      bgcolor: 'rgba(255, 184, 0, 0.24)',
                      p: '0 8px',
                      fontWeight: 600,
                      fontSize: '13px',
                      color: 'text.primary',
                      minWidth: 'auto',
                      ':hover': {
                        bgcolor: 'rgba(255, 184, 0, 0.24)',
                      },
                    }}
                  >
                    {open ? (
                      <ClearIcon
                        width={18}
                        height={18}
                        viewBox='0 0 24 24'
                        color='rgba(34, 34, 34, 1)'
                      />
                    ) : (
                      `+${restAttrs.length}`
                    )}
                  </Button>
                  <Collapse in={open} timeout='auto'>
                    <Grid
                      container
                      spacing={'4px'}
                      sx={{
                        flexWrap: 'wrap',
                        position: 'absolute',
                        top: '100%',
                        right: 0,
                        backgroundColor: 'white',
                        mt: 0,
                        borderRadius: '11px',
                        zIndex: '1',
                        pb: '4px',
                      }}
                    >
                      {restAttrs?.map(attr => (
                        <Grid key={attr} item>
                          <Label title={attr} />
                        </Grid>
                      ))}
                    </Grid>
                  </Collapse>
                </>
              )}
            </Stack>

            <Stack spacing={'8px'}>
              <AttributesRow
                items={[capitalize(condition)]}
                label='Condition'
              />
              <AttributesRow
                items={[
                  `${itemCount} Items`,
                  totalWeight ? `${totalWeight} lbs` : '',
                  totalPackages,
                ]}
                label='Lot size'
              />
              {manufacturer && (
                <AttributesRow
                  items={manufacturer}
                  label='Mfr.'
                  isItemsEllipsis
                />
              )}
              <AttributesRow
                items={[address]}
                label='Location'
                isItemsEllipsis
              />
            </Stack>
          </CardContent>
          <Divider
            orientation='vertical'
            flexItem
            sx={{ display: { xs: 'none', lg: 'flex' } }}
          />
          <Divider />
          <Stack
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: {
                xs: '100%',
                lg: '208px',
                xl: '244px',
              },
              pl: {
                xs: '0',
                lg: '16px',
              },
              pt: {
                xs: '8px',
                lg: '0',
              },
            }}
          >
            <Stack
              direction={'row'}
              sx={{
                mb: '12px',
                alignItems: 'baseline',
              }}
            >
              <Typography variant={'h2'}>
                {formatCurrency(Number(bidToShow), {
                  withPrefix: true,
                  minimumFractionDigits: 0,
                })}
              </Typography>

              <Typography
                variant={'body2'}
                sx={{
                  ml: '4px',
                }}
              >
                {status === 'sold'
                  ? 'winning bid'
                  : ['awaiting', 'closed'].includes(status)
                  ? 'last bid'
                  : totalBids === 1
                  ? `${totalBids} bid`
                  : `${totalBids} bids`}
              </Typography>
            </Stack>

            {status === 'active' && (
              <AuctionTimer
                auctionStatus={status as AuctionStatusEnum}
                endDate={endDate}
              />
            )}

            {!isCurrentAuction && status === 'active' && (
              <Typography
                variant={'body2'}
                sx={{
                  mt: '8px',
                  color: 'text.secondary',
                }}
              >
                ends in
              </Typography>
            )}

            {isCurrentAuction && status === 'active' && (
              <Stack
                sx={{
                  alignItems: 'center',
                  borderRadius: '12px',
                  p: '11px 0 14px',
                  mt: '16px',
                  bgcolor: 'primary.100',
                  width: '228px',
                }}
              >
                <BidLabel isHighest={isMyBidHighest} />
                <Stack
                  direction={'row'}
                  sx={{
                    alignItems: 'baseline',
                    m: '4px 0 10px',
                  }}
                >
                  <Typography variant={'h2'}>
                    {formatCurrency(myMaxBid, {
                      withPrefix: true,
                      minimumFractionDigits: 0,
                    })}
                  </Typography>
                  <Typography
                    variant={'body2'}
                    sx={{
                      ml: '4px',
                    }}
                  >
                    your max bid
                  </Typography>
                </Stack>

                <PriceStatus
                  hasReservePrice={hasReservePrice}
                  isReservePriceMet={isReservePriceMet}
                />
              </Stack>
            )}

            {/* variant 3 - with status --- START */}
            {status !== 'active' && (
              <>
                <AuctionStatus title={status} fontSize={'14px'} />
                <Typography
                  variant={'body2'}
                  sx={{
                    color: 'text.secondary',
                    maxWidth: '175px',
                    textAlign: 'center',
                  }}
                >
                  Bidding has ended. <br />{' '}
                  {formatDate(new Date(endDate), "MMM d, yyyy 'at' h:mm aa")}
                </Typography>
              </>
            )}
            {/* variant 3 - with status --- END */}
          </Stack>
          <ModalCustom
            open={openRemoveModal}
            onCloseClick={() => setOpenRemoveModal(false)}
            onAcceptClick={handleAcceptDeleting}
            acceptText='Delete'
          >
            <>Do you really want to remove the auction from the watchlist?</>
          </ModalCustom>

          <ModalCustom
            open={authRequiredModalOpen}
            onCloseClick={() => setAuthRequiredModalOpen(false)}
            onAcceptClick={async () => {
              await router.push('/account/login')
              setAuthRequiredModalOpen(false)
            }}
            acceptText='Log in'
          >
            <>Authorization is required</>
          </ModalCustom>
        </Card>
      </Link>
    </Box>
  )
}
