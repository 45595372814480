import { Box, Button, Skeleton, Typography } from '@mui/material'
import React, { FC } from 'react'

import { Props } from './HeadingWithCounter.types'
export const HeadingWithCounter: FC<Props> = ({
  title,
  count,
  isActive,
  withoutZeroCount,
  activeButtonText = 'Show all',
  onActiveButtonClick,
  loading,
}) => {
  return (
    <>
      {loading ? (
        <Box display='flex' alignItems='center' sx={{ minWidth: '220px' }}>
          <Skeleton
            variant={'rectangular'}
            width={'24px'}
            height={'24px'}
            sx={{ mr: '12px', borderRadius: '4px' }}
          />
          <Skeleton variant={'text'}>
            <Typography component={'span'} textTransform={'uppercase'}>
              {title}
            </Typography>
          </Skeleton>
        </Box>
      ) : (
        <Box display='flex' alignItems='center' sx={{ minWidth: '220px' }}>
          <Box
            sx={{
              display: withoutZeroCount && count === 0 ? 'none' : 'block',
              bgcolor: isActive ? 'primary.main' : 'grey.100',
              color: isActive ? 'white' : 'text.main',
              borderRadius: '5px',
              padding: '0 8px',
              mr: '12px',
            }}
          >
            <Typography fontSize={17} fontWeight={600}>
              {count}
            </Typography>
          </Box>
          <Typography
            fontSize={17}
            fontWeight={600}
            lineHeight={'24px'}
            textTransform='uppercase'
          >
            {title}
          </Typography>
          {isActive && (
            <Button
              onClick={onActiveButtonClick}
              aria-label='show all auctions'
              sx={{
                fontSize: '16px',
                fontWeight: 600,
                '&:hover': {
                  backgroundColor: 'transparent',
                },
                ml: '4px',
              }}
            >
              {activeButtonText}
            </Button>
          )}
        </Box>
      )}
    </>
  )
}
